import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Switch from "react-switch"
import { useTranslation } from "react-i18next"
import EventImagesModal from "./EventsImagesModal"
import UpgradeImagePack from "@/components/Payments/UpgradeImagePack"
import UpgradeImagePackWithStripe from "@/components/Payments/UpgradeImagePackWithStripe"
import { getEvent, enablePov } from "@/redux/slices/events/eventsSlice"
import {
  subscribePov,
  getPovSubscription,
} from "@/redux/slices/payments/paymentsSlices"
import Spinner from "react-bootstrap/Spinner"
import api from "@/api/api"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { Form } from "react-bootstrap"
import styled from "styled-components" // Assuming you're using styled-components
import { toast } from "react-toastify"
import styles from "./FrontPagesImages.module.scss"

interface FrontImagesProps {
  event_id: string | number // Adjust type based on your actual data
}

const FrontImages: React.FC<FrontImagesProps> = ({ event_id }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [show, setShow] = useState<boolean>(false)
  const event = useAppSelector((state) => state.events.currentEvent)
  const { enablePovLoading, enablePovError } = useAppSelector(
    (state) => state.events,
  )
  const {
    isSubscriptionLoading,
    errorSubscription,
    isSubscriptionCreatingLoading,
    errorCreateSubscription,
    entitlements,
    currentPovPlan,
  } = useAppSelector((state) => state.payments)

  const { email } = useAppSelector((state) => state.auth)

  const [showUpgrade, setShowUpgrade] = useState<boolean>(false)
  const [images, setImages] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleImagesCount, setVisibleImagesCount] = useState(6)
  const [remainingImagesCount, setRemainingImagesCount] = useState(0)
  console.log("event", event)
  const [isChecked, setIsChecked] = useState<boolean>(event?.pov_enabled === 1)
  const [fromPhotos, setFromPhotos] = useState<boolean>(false)
  // console.log("event?.pov_enabled", event?.pov_enabled)
  // console.log("isChecked", isChecked)

  const [loadingSWitch, setLoadingSWitch] = useState<boolean>(false)
  const handleSwitchChange = async (checked: boolean) => {
    setLoadingSWitch(true)
    try {
      await api.events.enablePov(event_id, checked)
      toast.success(`POV ${checked ? "enabled" : "disabled"} successfully`)

      // toast.success(
      //   t("POV", {
      //     status: checked
      //       ? t("event.photos.enabled")
      //       : t("event.photos.disabled"),
      //   }),
      // )

      setIsChecked(checked)
    } catch (error) {
      console.error("Error while toggling POV:", error)
      toast.error("Error while toggling POV")
      // Don't change the isChecked state if there's an error
    } finally {
      setLoadingSWitch(false)
    }
  }
  // dispatch(getEvent(Number(event_id))).then((data: any) => {
  //   console.log("data", data)
  // })  }
  // const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setLoadingSWitch(true)
  //   try {
  //     const response = api.events
  //       .enablePov(Number(event_id), e.target.checked)
  //       .then((data: any) => {
  //         if (e.target.checked == true)
  //           toast.success("POV enabled successfully")
  //         if (e.target.checked == false)
  //           toast.success("POV disabled successfully")
  //         setLoadingSWitch(false)
  //         setIsChecked(e.target.checked)
  //       })
  //   } catch (data: any) {
  //     console.log("data", data)
  //     toast.error("Error while enabling POV")
  //     setLoadingSWitch(false)
  //     setIsChecked(isChecked)
  //   }
  //   // dispatch(getEvent(Number(event_id))).then((data: any) => {
  //   //   console.log("data", data)
  //   // })
  // }

  useEffect(() => {
    if (email) dispatch(getPovSubscription(email))
  }, [show, event_id, isChecked])
  useEffect(() => {
    setLoading(true)
    const fetchEventImages = async () => {
      try {
        const response = await api.events.getEventImages(event_id)
        setImages(response.data.data)
        setLoading(false)
      } catch (err: any) {
        setError(err)
        setLoading(false)
      }
    }

    fetchEventImages()
  }, [event_id, show, showUpgrade])

  useEffect(() => {
    // Function to handle screen size changes
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 576) {
        setVisibleImagesCount(2)
      } else {
        setVisibleImagesCount(6)
      }
    }

    // Add event listener for resize
    window.addEventListener("resize", handleResize)

    // Initial call
    handleResize()

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    // Calculate remaining images count based on visible images
    const totalImages = images.length
    const remaining = Math.max(totalImages - visibleImagesCount, 0)
    setRemainingImagesCount(remaining)
  }, [images, visibleImagesCount])

  const handleClose = () => {
    setShow(false)
  }
  const handleCloseUpgrade = () => {
    setShowUpgrade(false)
  }
  const handleOpenUpgrade = () => {
    handleClose()
    setShowUpgrade(true)
    setFromPhotos(false)
  }

  return (
    <div>
      <h2 className=" mb-3">
        {t("event.photos.eventPhotos")}
        {/* Event photos */}
      </h2>
      <div className=" mt-3 mb-4  d-flex justify-content-between align-items-center">
        <h6 className="mr-3 fs-6" style={{ fontWeight: 500 }}>
          {t("event.photos.allowGuestsToUploadPhotosViaMazoomPov")}
          {/* Allow your guests to capture memorable moments using the POV app */}
          {/* {t("event.photos.eventPhotos")} */}
        </h6>

        {loadingSWitch ? (
          <>
            <Spinner className="spinner-border spinner-border-sm d-md-none" />
            <Spinner className="spinner-border d-none d-md-block" />
          </>
        ) : (
          <>
            <div>
              <Switch
                onChange={handleSwitchChange}
                checked={isChecked}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={28}
                width={60}
                handleDiameter={24}
                onColor="#000000"
                onHandleColor="#ffffff"
                offColor="#C4C4C4" // Changed to grey
                offHandleColor="#ffffff"
                className={`custom-switch ${isChecked ? "switched" : ""}`}
              />
            </div>
          </>
        )}
      </div>

      {isChecked && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!isSubscriptionLoading && (
                <>
                  <h2 style={{ marginBottom: "0px", fontSize: "25px" }}>
                    {/* {t("event.photos.basicPack")} */}
                    {currentPovPlan === "basic_pov_plan"
                      ? t("event.photos.basicPack")
                      : currentPovPlan === "standard_pov_plan"
                      ? t("event.photos.standardPack")
                      : currentPovPlan === "unlimited_pov_plan"
                      ? t("event.photos.unlimitedPack")
                      : t("event.photos.basicPack")}
                  </h2>
                  <h6 className={styles.text}>
                    {/* {t("event.photos.guestsCanUploadUpTo10Photos")} */}
                    {currentPovPlan === "basic_pov_plan"
                      ? t("event.photos.guestsCanUploadUpTo10Photos")
                      : currentPovPlan === "standard_pov_plan"
                      ? t("event.photos.guestsCanUploadUpTo100Photos")
                      : currentPovPlan === "unlimited_pov_plan"
                      ? t(
                          "event.photos.guestsCanUploadAnUnlimitedNumberOfPhotos",
                        )
                      : t("event.photos.guestsCanUploadUpTo10Photos")}
                  </h6>
                </>
              )}
            </div>

            {/* {isShowOovScreen && ( */}
            <Link
              className="fs-6"
              to="#"
              // onClick={() => setShowUpgrade(true)}
              onClick={handleOpenUpgrade}
            >
              {t("event.photos.upgrade")}
              {/* Upgrade */}
            </Link>
          </div>
          {loading && (
            <div className="d-flex justify-content-center">
              {" "}
              <Spinner />
            </div>
          )}
          {images && images?.length > 0 ? (
            <>
              <div className="mb-3 d-flex flex-column align-items-start">
                <div className="container">
                  <div className="d-flex flex-column flex-lg-row align-items-start">
                    {/* Images Container */}
                    <div className="d-flex flex-wrap flex-grow-1 w-100">
                      <div className="row g-3 w-100">
                        {images &&
                          images.length > 0 &&
                          images
                            .slice(0, visibleImagesCount)
                            .map((image: any, index: number) => (
                              <div
                                className="col-12 col-md-6 col-lg-4 col-xl-2" // 6 images per row on lg screens (4 columns per row)
                                key={index}
                              >
                                <div
                                  className="position-relative d-none d-lg-flex  "
                                  style={{
                                    overflow: "hidden",
                                    height: "85px", // Adjust height as needed
                                  }}
                                >
                                  <img
                                    className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
                                    src={image?.image_url}
                                    alt="Image"
                                    style={{
                                      maxWidth: "100%",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "8px", // Adjust the value as needed
                                    }}
                                  />
                                </div>
                                <div
                                  className="position-relative d-flex d-sm-none d-lg-none "
                                  style={{
                                    overflow: "hidden",
                                    height: "110px", // Adjust height as needed
                                  }}
                                >
                                  <img
                                    className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
                                    src={image?.image_url}
                                    alt="Image"
                                    style={{
                                      maxWidth: "100%",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "8px", // Adjust the value as needed
                                    }}
                                  />
                                </div>
                                <div
                                  className="position-relative d-none d-sm-flex  d-md-none"
                                  style={{
                                    overflow: "hidden",
                                    height: "150px", // Adjust height as needed
                                  }}
                                >
                                  <img
                                    className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
                                    src={image?.image_url}
                                    alt="Image"
                                    style={{
                                      maxWidth: "100%",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "8px", // Adjust the value as needed
                                    }}
                                  />
                                </div>

                                <div
                                  className="position-relative d-none  d-md-flex d-lg-none"
                                  style={{
                                    overflow: "hidden",
                                    height: "150px", // Adjust height as needed
                                  }}
                                >
                                  <img
                                    className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
                                    src={image?.image_url}
                                    alt="Image"
                                    style={{
                                      maxWidth: "100%",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "8px", // Adjust the value as needed
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>

                    {/* Link Container */}
                    {remainingImagesCount > 0 && (
                      <div
                        className="d-none d-lg-flex justify-content-center align-items-center  flex-shrink-1"
                        style={{ flex: "0 0 25%" }}
                      >
                        <button
                          className="btn btn-dark w-100 h-100 d-flex flex-column justify-content-center align-items-center py-3"
                          onClick={() => setShow(true)}
                        >
                          <span>
                            {" "}
                            <span className="me-1 ">
                              {t("event.photos.seeAll")}
                            </span>
                            {/* <span className="me-0">{remainingImagesCount}</span> */}
                            <span className="me-0">{images.length}</span>
                          </span>
                          <span>
                            <span>{t("event.photos.photos")}</span>
                          </span>
                        </button>
                      </div>
                    )}
                  </div>

                  {/* Link for smaller screens */}
                  {remainingImagesCount > 0 && (
                    <div
                      className="mt-3 d-flex d-lg-none justify-content-center align-items-center  flex-shrink-1"
                      style={{ flex: "0 0 25%" }}
                    >
                      <button
                        className="btn btn-dark w-100 h-100 d-flex flex-column justify-content-center align-items-center py-3"
                        onClick={() => setShow(true)}
                      >
                        <span>
                          {" "}
                          <span className="me-1 ">
                            {t("event.photos.seeAll")}
                          </span>
                          {/* <span className="me-0">{remainingImagesCount}</span> */}
                          <span className="me-0">{images.length}</span>
                        </span>

                        <span>
                          <span>{t("event.photos.photos")}</span>
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* <button
                className="btn btn-dark mt-4 ps-5 pe-5"
                onClick={() => setShow(true)}
              >
                see all photos
              </button> */}
              {/* {t("event.photos.seeAllPhotos")} */}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <img
                  src="/images/noImage.png"
                  alt="no image"
                  style={{ maxHeight: "110px", marginBottom: "20px" }}
                />
              </div>
            </>
          )}
        </>
      )}

      <EventImagesModal
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        images={images}
        setImages={setImages}
        setShowUpgrade={setShowUpgrade}
        showUpgrade={showUpgrade}
        event={event}
        fromPhotos={fromPhotos}
        setFromPhotos={setFromPhotos}
      />
      <UpgradeImagePack
        fromPhotos={fromPhotos}
        setShowUpgrade={setShowUpgrade}
        showUpgrade={showUpgrade}
        handleCloseUpgrade={handleCloseUpgrade}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        images={images}
        setImages={setImages}
      />
    </div>
  )
}

export default FrontImages
