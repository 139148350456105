import { useEffect, useState } from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { t } from "i18next"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  authentication,
  setError,
  setProfileError,
} from "../../redux/slices/auth/authSlice"
import {
  IconBalance,
  IconBtnCreate,
  IconChange,
  IconCheck,
  IconProfileInfo,
  IconUpgrade,
} from "../../components/utils/Icons/CustomIcons"
import ProfileField from "../../components/Profile/ProfileField"
import "react-toastify/dist/ReactToastify.css"

interface IForm {
  first_name: string
  last_name: string
  email: string
  phone: string
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const errorsCheck = {
  email: [t("errors.email")],
  phone: [t("errors.incorrectPhone")],
  first_name: [t("errors.firstName")],
  last_name: [t("errors.lastName")],
}

const ProfilePage = () => {
  const navigate = useNavigate()
  const {
    balance,
    first_name,
    last_name,
    email,
    phone,
    is_premium,
    error,
    isAuth,
  } = useAppSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  let defaultPersonal = {
    first_name: first_name || "",
    last_name: last_name || "",
    email: email || "",
    phone: phone || "",
  }

  let defaultSecurity = {
    old_password: "",
    password: "",
    confirmPassword: "",
  }

  const checkEmptyInput = (value: string) => {
    if (!!!value) {
      return "empty"
    }
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<any>({})

  const [isChangePersonal, setIsChangePersonal] = useState(true)
  const [isChangeSecurity, setIsChangeSecurity] = useState(true)

  const [personal, setPersonal] = useState(defaultPersonal)
  const [security, setSecurity] = useState(defaultSecurity)

  const notify = (text: string) => {
    toast.success(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  useEffect(() => {
    isAuth &&
      setPersonal({
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email: email ? email : "",
        phone: phone ? phone : "",
      })
  }, [first_name, last_name, email, phone])

  const validation = (person: any, params: any) => {
    const emailCheck = /\S+@\S+\.\S+/.test(person.email)
    const phoneCheck = person.phone.toString().match("[0-9]{10}")
    const firstCheck = person.first_name.length
    const secondCheck = person.last_name.length

    if (
      emailCheck === false &&
      !phoneCheck &&
      firstCheck === 1 &&
      secondCheck === 1
    ) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            phone: errorsCheck.phone,
            first_name: errorsCheck.first_name,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (emailCheck === false && !phoneCheck && firstCheck === 1) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            phone: errorsCheck.phone,
            first_name: errorsCheck.first_name,
          },
        }),
      )
    } else if (emailCheck === false && !phoneCheck && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            phone: errorsCheck.phone,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (!phoneCheck && firstCheck === 1 && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            phone: errorsCheck.phone,
            first_name: errorsCheck.first_name,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (emailCheck === false && firstCheck === 1 && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            first_name: errorsCheck.first_name,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (emailCheck === false && !phoneCheck) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            phone: errorsCheck.phone,
          },
        }),
      )
    } else if (firstCheck === 1 && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            first_name: errorsCheck.first_name,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (emailCheck === false && firstCheck === 1) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            first_name: errorsCheck.first_name,
          },
        }),
      )
    } else if (emailCheck === false && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (!phoneCheck && firstCheck === 1) {
      dispatch(
        setError({
          errors: {
            phone: errorsCheck.phone,
            first_name: errorsCheck.first_name,
          },
        }),
      )
    } else if (!phoneCheck && secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            phone: errorsCheck.phone,
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else if (emailCheck === false) {
      dispatch(
        setError({
          errors: {
            email: errorsCheck.email,
          },
        }),
      )
    } else if (!phoneCheck) {
      dispatch(
        setError({
          errors: {
            phone: errorsCheck.phone,
          },
        }),
      )
    } else if (firstCheck === 1) {
      dispatch(
        setError({
          errors: {
            first_name: errorsCheck.first_name,
          },
        }),
      )
    } else if (secondCheck === 1) {
      dispatch(
        setError({
          errors: {
            last_name: errorsCheck.last_name,
          },
        }),
      )
    } else {
      dispatch(authentication(params)).then((res) => {
        if (!res.payload.errors) {
          setIsChangePersonal(true)
          notify(t("notify.dataSuccess"))
        }
      })
    }
  }

  const passwordValidation = (params: any) => {
    if (
      security.password !== security.confirmPassword &&
      security.old_password.length < 8
    ) {
      dispatch(
        setError({
          errors: {
            password: [t("errors.passwordsNotMutch")],
            confirmPassword: [t("errors.passwordsNotMutch")],
            old_password: [t("errors.incorrect")],
          },
        }),
      )
    } else if (
      security.password.length < 8 &&
      security.confirmPassword.length < 8 &&
      security.old_password.length < 8
    ) {
      dispatch(
        setError({
          errors: {
            password: [t("errors.mustBeMore")],
            confirmPassword: [t("errors.mustBeMore")],
            old_password: [t("errors.incorrect")],
          },
        }),
      )
    } else if (security.password !== security.confirmPassword) {
      dispatch(
        setError({
          errors: {
            password: [t("errors.passwordsNotMutch")],
            confirmPassword: [t("errors.passwordsNotMutch")],
          },
        }),
      )
    } else if (security.old_password.length < 8) {
      dispatch(
        setError({
          errors: {
            old_password: [t("errors.incorrect")],
          },
        }),
      )
    } else {
      dispatch(authentication(params)).then((res: any) => {
        if (!res.error) {
          setIsChangeSecurity(true)
          setSecurity(defaultSecurity)
          notify(t("message.title"))
        }
      })
    }
  }

  return (
    <div className="profile-section">
      <div className="container container-profile">
        <div className="title-page">
          <h2 className="caption-two">{t("profile.titlePageProfile")}</h2>
        </div>

        <div className="profile-row">
          <div className="profile-col profile-col--balance bl-default">
            <div className="profile-left">
              <h2 className="caption caption-three">
                {t("profile.availableBalance")}
              </h2>

              <div className="profile-info">
                <IconBalance />
                <span className="profile-balance__value">
                  {balance} {t("profile.credits")}
                </span>
                <OverlayTrigger
                  containerPadding={
                    window.innerWidth < 1279
                      ? window.innerWidth > 767
                        ? 193
                        : 16
                      : 203
                  }
                  placement="top"
                  overlay={
                    <Tooltip
                      style={{
                        fontSize: "10px",
                        width: `${window.innerWidth < 767 ? "100%" : ""}`,
                        maxWidth: `${
                          window.innerWidth < 767 ? "calc(100% - 32px)" : ""
                        }`,
                        fontFamily: "Inter",
                        fontWeight: "400",
                        lineHeight: "14px",
                        padding: `${
                          window.innerWidth > 767 ? "10px 14px" : "10px 10px"
                        }`,
                        marginBottom: "5px",
                      }}
                      className="profile-tooltip"
                      id="tooltipCredits"
                    >
                      {t("profile.tooltip.credits")}
                    </Tooltip>
                  }
                >
                  <div className="profile-tooltip__content">
                    <IconProfileInfo color="dark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <Button
              className="btn btn-dark"
              onClick={() => navigate("/credits")}
            >
              <IconBtnCreate />
              {t("buttons.topUP")}
            </Button>
          </div>

          <div className="profile-col profile-col--plan bl-default">
            <div className="profile-left">
              <h2 className="caption caption-three">
                {t("profile.membershipPlan")}
              </h2>

              <div className="profile-info">
                <IconCheck />

                <span className="profile-balance__value">
                  {is_premium
                    ? t("profile.premiumPlan")
                    : t("profile.freePlan")}
                </span>

                {!is_premium ? (
                  <OverlayTrigger
                    containerPadding={
                      window.innerWidth < 1279
                        ? window.innerWidth > 767
                          ? 530
                          : 16
                        : 740
                    }
                    placement="top"
                    overlay={
                      <Tooltip
                        style={{
                          fontSize: "10px",
                          width: `${window.innerWidth < 767 ? "100%" : ""}`,
                          maxWidth: `${
                            window.innerWidth < 767 ? "calc(100% - 32px)" : ""
                          }`,
                          fontFamily: "Inter",
                          lineHeight: "14px",
                          padding: `${
                            window.innerWidth > 767 ? "10px 14px" : "10px 10px"
                          }`,
                          marginBottom: "5px",
                        }}
                        className="profile-tooltip"
                        id="tooltipPlan"
                      >
                        {t("profile.tooltip.plan")}
                      </Tooltip>
                    }
                  >
                    <div className="profile-tooltip__content">
                      <IconProfileInfo />
                    </div>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </div>
            </div>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="header-tooltip" id="tooltip">
                  {is_premium
                    ? t("profile.tooltip.downgradePlan")
                    : t("profile.tooltip.upgradePlan")}
                </Tooltip>
              }
            >
              <Button className="btn btn-dark">
                <IconUpgrade color="dark" />
                {is_premium ? t("buttons.downgrade") : t("buttons.upgrade")}
              </Button>
            </OverlayTrigger>
          </div>

          <div className="profile-col bl-default">
            <div className="profile-col__head">
              <h2 className="caption caption-three">
                {t("profile.personalTitle")}
              </h2>
              <div className="profile-controls">
                {isChangePersonal ? (
                  <button
                    className="btn btn-simple"
                    onClick={() => {
                      setIsChangePersonal(false)
                    }}
                  >
                    <IconChange />
                    {t("buttons.edit")}
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-simple btn-cancel"
                      onClick={() => {
                        setPersonal(defaultPersonal)
                        setIsChangePersonal(true)
                        dispatch(
                          setError({
                            errors: {},
                          }),
                        )
                      }}
                    >
                      {t("buttons.cancel")}
                    </button>
                    <button
                      className="btn btn-dark btn-save"
                      onClick={() => {
                        let params: { [key: string]: string } = {}

                        for (let property in personal) {
                          if (
                            personal[property as keyof typeof personal] !==
                            defaultPersonal[
                              property as keyof typeof defaultPersonal
                            ]
                          ) {
                            params[property] =
                              personal[property as keyof typeof personal]
                          }
                        }

                        validation(personal, params)
                      }}
                    >
                      {t("buttons.save")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="profile-col__body">
              <form className="profile-form profile-form--personal">
                <ProfileField
                  name={"first_name"}
                  type="text"
                  label={t("placeholder.firstName")}
                  disabled={isChangePersonal}
                  value={personal.first_name}
                  register={register}
                  error={errors.first_name?.message || error}
                  className={checkEmptyInput(personal.first_name)}
                  onHandleChange={(value) => {
                    setPersonal({
                      ...personal,
                      first_name: value,
                    })
                  }}
                />
                <ProfileField
                  name={"last_name"}
                  type="text"
                  label={t("placeholder.lastNameText")}
                  disabled={isChangePersonal}
                  value={personal.last_name}
                  error={errors.last_name?.message || error}
                  register={register}
                  className={checkEmptyInput(personal.last_name)}
                  onHandleChange={(value) => {
                    setPersonal({
                      ...personal,
                      last_name: value,
                    })
                  }}
                />
                <ProfileField
                  name={"email"}
                  type="email"
                  label={t("placeholder.email")}
                  disabled={true}
                  value={personal.email}
                  error={errors.email?.message || error}
                  register={register}
                  className={checkEmptyInput(personal.email)}
                  onHandleChange={(value) => {
                    setPersonal({
                      ...personal,
                      email: value,
                    })
                  }}
                />
                <ProfileField
                  name={"phone"}
                  type="tel"
                  label={t("placeholder.number")}
                  disabled={true}
                  value={personal.phone}
                  error={errors.phone?.message || error}
                  register={register}
                  className={checkEmptyInput(personal.phone)}
                  onHandleChange={(value) => {
                    setPersonal({
                      ...personal,
                      phone: value,
                    })
                  }}
                />
              </form>
            </div>
            <div className="profile-controls profile-controls--mobile">
              {isChangePersonal ? (
                <button
                  className="btn btn-simple"
                  onClick={() => {
                    setIsChangePersonal(false)
                  }}
                >
                  <IconChange />
                  {t("buttons.edit")}
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-simple btn-cancel"
                    onClick={() => {
                      setPersonal(defaultPersonal)
                      setIsChangePersonal(true)
                    }}
                  >
                    {t("buttons.cancel")}
                  </button>
                  <button
                    className="btn btn-dark btn-save"
                    onClick={() => {
                      setIsChangePersonal(true)
                    }}
                  >
                    {t("buttons.save")}
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="profile-col bl-default">
            <div className="profile-col__head">
              <h2 className="caption caption-three">
                {t("profile.passwordTitle")}
              </h2>
              <div className="profile-controls">
                {isChangeSecurity ? (
                  <button
                    className="btn btn-simple"
                    onClick={() => {
                      setIsChangeSecurity(false)
                    }}
                  >
                    <IconChange />
                    {t("buttons.change")}
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-simple btn-cancel"
                      onClick={() => {
                        setSecurity(defaultSecurity)
                        setIsChangeSecurity(true)
                        dispatch(
                          setError({
                            errors: {},
                          }),
                        )
                      }}
                    >
                      {t("buttons.cancel")}
                    </button>
                    <button
                      className="btn btn-dark btn-save"
                      onClick={() => {
                        let params: { [key: string]: string } = {}

                        for (let property in security) {
                          if (
                            security[property as keyof typeof security] !==
                            defaultSecurity[
                              property as keyof typeof defaultSecurity
                            ]
                          ) {
                            params[property] =
                              security[property as keyof typeof security]
                          }
                        }
                        passwordValidation(params)
                      }}
                    >
                      {t("buttons.save")}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="profile-col__body">
              <form className="profile-form profile-form--password">
                <ProfileField
                  name={"old_password"}
                  type="password"
                  label={t("placeholder.currentPassword")}
                  disabled={isChangeSecurity}
                  value={security.old_password}
                  error={error}
                  register={register}
                  className={checkEmptyInput(security.old_password)}
                  onHandleChange={(value) => {
                    setSecurity({
                      ...security,
                      old_password: value,
                    })
                  }}
                />
                <ProfileField
                  name={"password"}
                  type="password"
                  label={t("placeholder.newPassword")}
                  disabled={isChangeSecurity}
                  value={security.password}
                  error={errors.password?.message || error}
                  register={register}
                  className={checkEmptyInput(security.password)}
                  onHandleChange={(value) => {
                    setSecurity({
                      ...security,
                      password: value,
                    })
                  }}
                />
                <ProfileField
                  name={"confirmPassword"}
                  type="password"
                  label={t("placeholder.confirmPassword")}
                  disabled={isChangeSecurity}
                  value={security.confirmPassword}
                  error={errors.password?.message || error}
                  register={register}
                  className={checkEmptyInput(security.confirmPassword)}
                  onHandleChange={(value) => {
                    console.log(error, errors)
                    setSecurity({
                      ...security,
                      confirmPassword: value,
                    })
                  }}
                />
              </form>
              <ToastContainer
                style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
              />
            </div>

            <div className="profile-controls profile-controls--mobile">
              {isChangeSecurity ? (
                <button
                  className="btn btn-simple"
                  onClick={() => {
                    setIsChangeSecurity(false)
                  }}
                >
                  <IconChange />
                  {t("buttons.change")}
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-simple btn-cancel"
                    onClick={() => {
                      setSecurity(defaultSecurity)
                      setIsChangeSecurity(true)
                      dispatch(
                        setError({
                          errors: {
                            email: errorsCheck.email,
                            phone: errorsCheck.phone,
                          },
                        }),
                      )
                      dispatch(setProfileError(""))
                    }}
                  >
                    {t("buttons.cancel")}
                  </button>
                  <button
                    className="btn btn-dark btn-save"
                    onClick={() => {
                      setIsChangeSecurity(true)
                    }}
                  >
                    {t("buttons.save")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
