import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { store } from "../../store"
import api from "../../../api/api"
import {
  IEventsInitState,
  IEventsRequest,
  IEventParams,
  IEventDesignParams,
} from "../../../components/Events/types"

dayjs.extend(utc)

const initialState: IEventsInitState = {
  data: [],
  search_value: "",
  new_event_id: null,
  new_event_img: {
    id: 0,
    file_path: "",
  },
  new_qr_img: {
    id: 0,
    file_path: "",
  },
  qr_id: null,
  preview: {},
  current_page: 1,
  total_pages: 0,
  total: 0,
  isLoading: false,
  error: "",
  currentLang: "",
  currentEvent: null,
  totalEventsCount: 0,
  error_text: "",
  invitation_status: 7,
  message_status: 0,
  thank_message: {
    en: {
      body_text: "",
    },
    ar: {
      body_text: "",
    },
  },
  isTimeout: false,
  discount: {
    discount_id: null,
    discount: null,
    discount_percent: null,
    limit: null,
  },
  pricing: {},
  success: "",
  phone: "",
  event_by_hash: {
    id: 0,
    name: "",
    open_event_hash: "",
    event_image: "",
    location: "",
    start_datetime: null,
    event_contact_accepted: null,
    open_event_email_required: 0,
    open_event_number_of_guests_max: 0,
    open_event_number_of_guests_required: 0,
    questions: { data: [] },
    venue: "",
  },
  not_found: false,
  open_hash: null,
  questions: [],
  is_open_event: 0,
  qr_design: null,
  qr_image: null,
  updated_qr_design: null,
  copy_event_id: null,
  enablePovLoading: false,
  enablePovError: "",
}

type createEventQuesionsParams = {
  order: number
  question: string
  type: number
  min: number
  max: number
  multiple: boolean
  options: { option: any; order: number }[] | []
}

type updateEventQuesionsParams = {
  id: number
  order: number
  question: string
  type: number
  min: number
  max: number
  multiple: boolean
  options: [] | { option: string; order: number }[]
}

type createQuestionOption = {
  order: number
  option: string
  id: number
}

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (params: IEventsRequest, { rejectWithValue }) => {
    try {
      const res = await api.events.events(
        store.getState().events.current_page,
        params,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await api.events.deleteEvent(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async (id: number, { rejectWithValue }) => {
    console.log("id", id)
    try {
      // if (id && id != undefined && id != null) {
      const res = await api.events.getEvent(id)
      return res.data
      // } else {
      //   return rejectWithValue("id not found")
      // }
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getPreview = createAsyncThunk(
  "events/getPreview",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.events.getPreview()
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (params: IEventParams, thunkAPI) => {
    let formatDate = moment(new Date()).format("Y-MM-DD")
    let formatTime = moment(new Date()).format("HH:mm")

    try {
      let data =
        store.getState().events.new_event_id !== null
          ? {
              event_id: store.getState().events.new_event_id,
              allow_to_add_invitation_to_wallets:
                params.qr_wallet === true ? 1 : 0,
              qr_code_design_id:
                store.getState().events.qr_id === null
                  ? params.qr_code_design_id
                  : store.getState().events.qr_id,
              simplified: true,
              orientation: "v",
              name: params.name,
              host_name: "host",
              event_created_date: formatDate,
              event_created_time: formatTime,
              address: {
                address: params.address.address,
                lat: params.address.lat,
                lng: params.address.lng,
              },
              text_style: {
                font_family: "Oxygen, sans-serif",
                font_size: "2x 1",
                font_style: {
                  textBold: false,
                  textItalic: false,
                  textUnderline: false,
                },
                color: "#000",
                text_align: "center",
              },
              text_position: "flex-start",
              invitation_show_qr_code:
                params.invitation_show_qr_code === true ? 1 : 0,
              is_open_event: params.is_open_event,
              start_datetime: dayjs
                .utc(params.start_datetime)
                .format("YYYY-MM-DD HH:mm:ss"),
              venue: params.venue,
              language:
                params.language === "English"
                  ? "en"
                  : params.language === "عرب"
                  ? "ar"
                  : params.language,
              type: params.type,
            }
          : {
              orientation: "v",
              allow_to_add_invitation_to_wallets:
                params.qr_wallet === true ? 1 : 0,
              name: params.name,
              simplified: true,
              host_name: "host",
              event_created_date: formatDate,
              event_created_time: formatTime,
              address: {
                address: params.address.address,
                lat: params.address.lat,
                lng: params.address.lng,
              },
              text_style: {
                font_family: "Oxygen, sans-serif",
                font_size: "2x 1",
                font_style: {
                  textBold: false,
                  textItalic: false,
                  textUnderline: false,
                },
                color: "#000",
                text_align: "center",
              },
              text_position: "flex-start",
              invitation_show_qr_code:
                params.invitation_show_qr_code === true ? 1 : 0,
              is_open_event: params.is_open_event,
              start_datetime: dayjs
                .utc(params.start_datetime)
                .format("YYYY-MM-DD HH:mm:ss"),
              venue: params.venue,
              language:
                params.language === "English"
                  ? "en"
                  : params.language === "عرب"
                  ? "ar"
                  : params.language,
              type: params.type,
            }
      const res = await api.events.createEvent(data)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const createQuestionOptions = createAsyncThunk(
  "events/createQuestionOptions",
  async (params: createQuestionOption, thunkAPI) => {
    try {
      const data = {
        order: params.order,
        option: params.option,
      }
      const res = await api.events.createQuesionsOption(params.id, data)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateQuestionOptions = createAsyncThunk(
  "events/updateQuestionOptions",
  async (params: createQuestionOption, thunkAPI) => {
    try {
      const data = {
        order: params.order,
        option: params.option,
      }
      const res = await api.events.updateQuesionsOption(params.id, data)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteQuestionOptions = createAsyncThunk(
  "events/deleteQuestionOptions",
  async (id: number, thunkAPI) => {
    try {
      const res = await api.events.deleteQuesionsOption(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const getOpenQuestions = createAsyncThunk(
  "events/getOpenQuestions",
  async (id: number, thunkAPI) => {
    try {
      const res = await api.events.getEventQuesions(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const sendOpenSettings = createAsyncThunk(
  "events/sendOpenSettings",
  async (params: any, thunkAPI) => {
    try {
      const res = await api.events.sendOpenSettings(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const createEventQuestions = createAsyncThunk(
  "events/createEventQuestions",
  async (params: createEventQuesionsParams, thunkAPI) => {
    try {
      let data = {
        event_id: store.getState().events.new_event_id,
        order: params.order,
        question: params.question,
        type: params.type,
        min_value: params.min,
        max_value: params.max,
        multiple_options_allowed: params.multiple,
        options: params.options,
      }
      const res = await api.events.createEventQuesions(data)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateEventQuestions = createAsyncThunk(
  "events/updateEventQuestions",
  async (params: updateEventQuesionsParams, thunkAPI) => {
    try {
      let data = {
        id: params.id,
        event_id: store.getState().events.new_event_id,
        order: params.order,
        question: params.question,
        type: params.type,
        min_value: params.min,
        max_value: params.max,
        multiple_options_allowed: params.multiple,
        options: params.options,
      }
      const res = await api.events.updateEventQuesions(params.id, data)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteEventQuestions = createAsyncThunk(
  "events/deleteEventQuestions",
  async (id: number, thunkAPI) => {
    try {
      const res = await api.events.deleteEventQuesions(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return error.response.data
        ? thunkAPI.rejectWithValue(error.response.data)
        : thunkAPI.rejectWithValue(message)
    }
  },
)

export const loadFileEvent = createAsyncThunk(
  "events/loadFileEvent",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.loadImgEvent(params)
      return res.data.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const deleteFileEvent = createAsyncThunk(
  "events/deleteFileEvent",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.events.deleteImgEvent(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const eventDesign = createAsyncThunk(
  "events/eventDesign",
  async (params: IEventDesignParams, thunkAPI) => {
    try {
      const res = await api.events.eventDesign(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const getQrDesign = createAsyncThunk(
  "events/getQrDesign",
  async (id: any, thunkAPI) => {
    try {
      const res = await api.events.getQrDesign(id)
      return res.data
    } catch (error: any) {
      let { errors } = error.response.data
      return thunkAPI.rejectWithValue(errors)
    }
  },
)

export const getQrImage = createAsyncThunk(
  "events/getQrImage",
  async (params: any, thunkAPI) => {
    try {
      const res = await api.events.getQrImage(params)
      return res.data
    } catch (error: any) {
      let { errors } = error.response.data
      return thunkAPI.rejectWithValue(errors)
    }
  },
)

export const sendQrDesign = createAsyncThunk(
  "events/sendQrDesign",
  async (params: any, thunkAPI) => {
    try {
      const res = await api.events.sendQrDesign(params)
      return res.data
    } catch (error: any) {
      let { errors } = error.response.data
      return thunkAPI.rejectWithValue(errors)
    }
  },
)

export const updateQrDesign = createAsyncThunk(
  "events/updateQrDesign",
  async (params: any, thunkAPI) => {
    try {
      const res = await api.events.updateQrDesign(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const uploadQRImage = createAsyncThunk(
  "events/uploadQRImage",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.uploadQRImage(params)
      return res.data.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendInvitationAll = createAsyncThunk(
  "events/sendInvitationAll",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendInvitationAll(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const testTheInvitation = createAsyncThunk(
  "events/testTheInvitation",
  async (phone: any, { rejectWithValue }) => {
    try {
      const res = await api.events.testTheInvitation(phone)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const validateOTP = createAsyncThunk(
  "events/validateOTP",
  async (otp: any, { rejectWithValue }) => {
    try {
      const res = await api.events.validateOTP(otp)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const updateInvites = createAsyncThunk(
  "events/updateInvites",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.updateInvites(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendQR = createAsyncThunk(
  "events/sendQR",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendQR(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendWAInvite = createAsyncThunk(
  "events/sendWAInvite",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendWAInvite(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const updateInvite = createAsyncThunk(
  "events/updateInvite",
  async (
    params: {
      event_id: number
      contact_id: number
      quantity_members: number
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await api.events.updateInvite(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getBalance = createAsyncThunk(
  "events/getBalance",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.events.getBalance()
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendReminder = createAsyncThunk(
  "events/sendReminder",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendReminder(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendThankYouMessage = createAsyncThunk(
  "events/sendThankYouMessage",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendThankYouMessage(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendTestInvitation = createAsyncThunk(
  "events/sendTestInvitation",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.sendTestInvitation(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response.data)
    }
  },
)

export const getTestInvitation = createAsyncThunk(
  "events/getTestInvitation",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.getTestInvitation(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getThankMessage = createAsyncThunk(
  "events/getThankMessage",
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await api.events.getThankMessage(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const checkDiscount = createAsyncThunk(
  "events/checkDiscount",
  async (code: string, { rejectWithValue }) => {
    try {
      const res = await api.events.checkDiscount(code)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response)
    }
  },
)

export const getPricing = createAsyncThunk(
  "events/getPricing",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.events.getPricing()
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response)
    }
  },
)

export const checkCharge = createAsyncThunk(
  "events/checkCharge",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.checkCharge(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response)
    }
  },
)

export const getEventByHash = createAsyncThunk(
  "events/getEventByHash",
  async (hash: string, { rejectWithValue }) => {
    try {
      const res = await api.events.getEventByHash(hash)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const registerAnswer = createAsyncThunk(
  "events/registerAnswer",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.regiterAnswer(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const copyEvent = createAsyncThunk(
  "events/copyEvent",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.events.copyEvent(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const enablePov = createAsyncThunk(
  "events/enablePov",
  async (
    { id, povEnabled }: { id: string | number; povEnabled: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.events.enablePov(id, povEnabled)
      return response.data
    } catch (error: any) {
      let { message } =
        error.response.data || "Error occured while enabling POV  "
      return rejectWithValue(message)
    }
  },
)

// helper

const handleloading = (state: IEventsInitState) => {
  state.isLoading = true
}

const handleError = (
  state: IEventsInitState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false
  state.error = action.payload
}

// slice

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading: handleloading,
    setError: handleError,
    clearImg: (state: IEventsInitState) => {
      state.new_event_img = {
        id: 0,
        file_path: "",
      }
    },
    setNewEventImg: (state: IEventsInitState, action) => {
      state.new_event_img = {
        id: action.payload.id,
        file_path: action.payload.img,
      }
    },
    clearEventId: (state) => {
      state.new_event_id = null
    },
    clearCurrentEvent: (state) => {
      state.currentEvent = null
    },
    clearQrId: (state) => {
      state.qr_id = null
      state.qr_design = null
      state.qr_image = null
      state.updated_qr_design = null
    },
    setSearchValue: (state, action) => {
      state.search_value = action.payload
    },
    setNewEventId: (state, action) => {
      state.new_event_id = action.payload
    },
    clearEvents: (state: IEventsInitState) => {
      state.data = []
    },
    setOpenHash: (state, action) => {
      state.open_hash = action.payload
    },
    setCurrentPage: (
      state: IEventsInitState,
      action: PayloadAction<number>,
    ) => {
      state.current_page = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getEvents.pending, (state, action) => {
      state.search_value !== ""
        ? (state.isLoading = false)
        : (state.isLoading = true)
      state.error = ""
    })

    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.data = [...action.payload.data]
      state.total_pages = action.payload.meta.pagination.total_pages
      state.current_page = action.payload.meta.pagination.current_page
      state.total = action.payload.meta.pagination.total
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.currentEvent = action.payload.data
      state.is_open_event = action.payload.data.is_open_event
      state.open_hash = action.payload.data.open_event_hash
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getEvent.pending, (state, action) => {
      state.isLoading = true
      state.currentEvent = null
      state.error = ""
    })

    builder.addCase(createEvent.pending, (state, action) => {
      state.isLoading = true
      state.error = ""
    })

    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.new_event_id = action.payload.data.event_id
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getEventByHash.fulfilled, (state, action) => {
      state.event_by_hash = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getEventByHash.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getEventByHash.rejected, (state, action) => {
      state.not_found = true
      state.isLoading = false
    })

    builder.addCase(createEventQuestions.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(createEventQuestions.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    builder.addCase(updateEventQuestions.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    builder.addCase(updateEventQuestions.rejected, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteEventQuestions.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(createQuestionOptions.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(updateQuestionOptions.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteQuestionOptions.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendOpenSettings.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(eventDesign.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })
    builder.addCase(sendQrDesign.fulfilled, (state, action) => {
      state.qr_id = action.payload.id
      state.isLoading = false
      state.error = ""
    })
    builder.addCase(updateQrDesign.pending, (state, action) => {
      state.isLoading = true
      state.error = ""
    })
    builder.addCase(updateQrDesign.fulfilled, (state, action: any) => {
      state.updated_qr_design = action.meta.arg.params
      console.log(action.meta.arg.params)
      state.isLoading = false
      state.error = ""
    })
    builder.addCase(getQrImage.fulfilled, (state, action) => {
      state.qr_image = action.payload
      state.isLoading = false
      state.error = ""
    })
    builder.addCase(getQrDesign.fulfilled, (state, action) => {
      console.log(action.payload)
      state.qr_design = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(uploadQRImage.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(updateInvite.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendWAInvite.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(updateInvites.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(loadFileEvent.fulfilled, (state, action) => {
      state.new_event_img.id = action.payload.id
      state.new_event_img.file_path = action.payload.file_path
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteFileEvent.fulfilled, (state, action) => {
      state.new_event_img.id = 0
      state.new_event_img.file_path = ""
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendInvitationAll.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(testTheInvitation.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getBalance.fulfilled, (state, action) => {
      console.log(action)
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendQR.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendReminder.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getTestInvitation.pending, (state, action) => {
      state.isLoading = true
      state.error = ""
    })

    builder.addCase(getTestInvitation.fulfilled, (state, action) => {
      state.isLoading = true
      state.error_text = action.payload.data.error_message
      state.invitation_status = action.payload.data.status
      state.message_status = action.payload.data.whatsapp_invitation_status
      state.phone = action.payload.data.phone
      state.isLoading = false
      state.error = ""
      state.isTimeout = false
    })

    builder.addCase(sendTestInvitation.fulfilled, (state, action) => {
      state.error = ""
      state.isLoading = true
      state.isTimeout = true
    })

    builder.addCase(sendTestInvitation.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    builder.addCase(getThankMessage.pending, (state, action) => {
      state.isLoading = true
      state.error = ""
    })

    builder.addCase(getThankMessage.fulfilled, (state, action) => {
      state.isLoading = true
      state.thank_message = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(checkDiscount.fulfilled, (state, action) => {
      state.discount = {
        discount_id: action.payload.data.id,
        discount: action.payload.data.discount,
        discount_percent: action.payload.data.discount_percent,
        limit: action.payload.data.limit,
      }
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getPricing.fulfilled, (state, action) => {
      state.pricing = action.payload.data
      state.isLoading = false
      state.error = ""
      state.success = ""
    })

    builder.addCase(checkCharge.fulfilled, (state, action) => {
      state.success = "true"
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(checkCharge.rejected, (state, action) => {
      state.success = "false"
      state.isLoading = false
    })

    builder.addCase(registerAnswer.fulfilled, (state, action) => {
      console.log(action.payload)
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getOpenQuestions.fulfilled, (state, action) => {
      state.questions = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(copyEvent.fulfilled, (state, action) => {
      state.copy_event_id = action.payload.data.event_id
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getPreview.fulfilled, (state, action) => {
      state.currentLang = localStorage.getItem("i18nextLng") || null

      // if (state.currentLang === "en") {
      //   state.preview = { ...action.payload.en }
      // }

      // if (state.currentLang === "ar") {
      //   state.preview = { ...action.payload.ar }
      // }

      state.preview = { ...action.payload }
      state.isLoading = false
      state.error = ""
    })

    builder
      .addCase(enablePov.pending, (state) => {
        state.enablePovLoading = true
        state.enablePovError = null
      })
      .addCase(
        enablePov.fulfilled,
        (
          state,
          action: PayloadAction<{ id: string | number; povEnabled: boolean }>,
        ) => {
          console.log("action.payload", action.payload)
          console.log("state.currentEvent", state.currentEvent)
          console.log("povEnabled")
          state.enablePovLoading = false
          state.enablePovError = null
        },
      )
      .addCase(enablePov.rejected, (state, action) => {
        state.enablePovLoading = false
        state.enablePovError = action.payload as string
      })
  },

  // extraReducers: {
  //   [getEvents.pending.type]: handleloading,
  //   [createEvent.pending.type]: handleloading,
  //   [loadFileEvent.pending.type]: handleloading,
  //   [deleteFileEvent.pending.type]: handleloading,
  //   [getPreview.pending.type]: handleloading,
  //   [eventDesign.pending.type]: handleloading,

  //   [getEvents.rejected.type]: handleError,
  //   [createEvent.rejected.type]: handleError,
  //   [loadFileEvent.rejected.type]: handleError,
  //   [deleteFileEvent.rejected.type]: handleError,
  //   [getPreview.rejected.type]: handleError,
  //   [eventDesign.rejected.type]: handleError,

  //   [getEvents.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.current_page = action.payload.meta.pagination.current_page
  //     state.total_pages = action.payload.meta.pagination.total_pages
  //     state.total = action.payload.meta.pagination.total
  //     state.data = [...action.payload.data]
  //     state.isLoading = false
  //     state.error = ""
  //   },

  //   [createEvent.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.isLoading = false
  //     state.error = ""
  //   },

  //   [loadFileEvent.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.new_event_img.id = action.payload.id
  //     state.new_event_img.file_path = action.payload.file_path
  //     state.isLoading = false
  //     state.error = ""
  //   },

  //   [deleteFileEvent.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.new_event_img.id = 0
  //     state.new_event_img.file_path = ""
  //     state.isLoading = false
  //     state.error = ""
  //   },

  //   [getPreview.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.currentLang = localStorage.getItem("i18nextLng") || null

  //     if (state.currentLang === "en") {
  //       state.preview = { ...action.payload.en }
  //     }

  //     if (state.currentLang === "ar") {
  //       state.preview = { ...action.payload.ar }
  //     }

  //     state.isLoading = false
  //     state.error = ""
  //   },

  //   [eventDesign.fulfilled.type]: (
  //     state: IEventsInitState,
  //     action: PayloadAction<IEventsResponse>,
  //   ) => {
  //     state.isLoading = false
  //     state.error = ""
  //   },
  // },
})

export const {
  setLoading,
  setError,
  setCurrentPage,
  clearEvents,
  clearImg,
  setSearchValue,
  clearEventId,
  setOpenHash,
  setNewEventId,
  setNewEventImg,
  clearQrId,
  clearCurrentEvent,
} = eventsSlice.actions

export default eventsSlice.reducer
