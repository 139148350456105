import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import authSlice from "./slices/auth/authSlice"
import eventsSlice from "./slices/events/eventsSlice"
import { qrSlice } from "./slices/qr/qrSlice"
import contactsSlices from "./slices/contacts/contactsSlices"
import messagesSlice from "./slices/messages/messagesSlice"
import paymentSubscriptionSlice from "./slices/payments/paymentsSlices"
import striperPaymentsSlice from "./slices/payments/stripeSlices"
export const store = configureStore({
  reducer: {
    auth: authSlice,
    events: eventsSlice,
    qr: qrSlice.reducer,
    contacts: contactsSlices,
    messages: messagesSlice,
    payments: paymentSubscriptionSlice,
    stripe: striperPaymentsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector // custom Hook TS
export const useAppDispatch = () => useDispatch<AppDispatch>() // custom Hook TS
