import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { sendThankYouMessage } from "../../../redux/slices/events/eventsSlice"
import { setBalance } from "../../../redux/slices/auth/authSlice"
import { getContactsOnEvent } from "../../../redux/slices/contacts/contactsSlices"
import styles from "./PreviewModal.module.scss"
import { useParams } from "react-router-dom"

const parseHTML = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")
  return doc.body
}

type Props = {
  show: boolean
  setShow: any
  checked: any
  setChecked: any
  setAllGuestsChecked: any
  filter: {
    sort: string
    order: string
    searchValue: string
    reminderNumber: number | string
    statusNumber: number | string
    id: number
  }
  name: string
  setName: any
}

const PreviewModal = ({
  show,
  setShow,
  checked,
  setChecked,
  setAllGuestsChecked,
  filter,
  name,
  setName,
}: Props) => {
  const { t } = useTranslation()
  const thankMessage = useAppSelector((state) => state.events.thank_message)
  const { lang } = useAppSelector((state) => state.auth)
  const [value, setValue] = useState("")
  const [text, setText] = useState("")
  const scrollContainerRef = useRef<HTMLTextAreaElement>(null)
  const [showError, setShowError] = useState(false)
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const onSendThankYouMessage = () => {
    if (value === "") {
      setShowError(true)
    } else {
      dispatch(
        sendThankYouMessage({
          event_contact_ids: checked,
          event_id: Number(event_id),
          message_text: value,
        }),
      ).then((result) => {
        if (result.payload) {
          dispatch(setBalance(result.payload.balance))
        }

        setShowError(false)
        setShow(false)
        setChecked([])
        setAllGuestsChecked([])
        notify(t("notify.messageSent"), false)
        setValue("")
        setText("")
        setName("")
      })
      dispatch(getContactsOnEvent(filter))
    }

    // if (checked.length > 0) {
    //   dispatch(
    //     sendReminder({
    //       event_contact_ids: checked.join(),
    //       event_id: Number(event_id),
    //       reminder_type: value,
    //     }),
    //   ).then((result) => {
    //     if (result.payload) {
    //       dispatch(setBalance(result.payload.balance))
    //     }
    //   })
    //   dispatch(getContactsOnEvent(filter))
    // }
  }

  const notify = (text: string, color: boolean) => {
    color
      ? toast.warning(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  const onChange = (e: any) => {
    if (scrollContainerRef.current) {
      if (scrollContainerRef.current.scrollHeight > 200) {
        setValue(value)
        setText(text)
      } else {
        setValue(e.target.value.replace(/\n/g, "<br/>"))
        setText(e.target.value)
      }
    } else {
      setValue(e.target.value.replace(/\n/g, "<br/>"))
      setText(e.target.value)
    }
  }

  const onHide = () => {
    setChecked([])
    setAllGuestsChecked([])
    setValue("")
    setText("")
    setShow(false)
    setName("")
  }

  return (
    <Modal
      className={`${styles.testModal} modal-thanks__preview`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("thank.modal.title")}
          </h2>
          <div className={styles.textContainer}>
            <span className={styles.text}>{t("thank.modal.description")}</span>
            <span
              className={showError ? styles.textError : styles.textErrorNone}
            >
              {t("thank.modal.error")}
            </span>
          </div>
          <textarea
            value={text}
            className={showError ? styles.textareaError : styles.textarea}
            placeholder={t("thank.modal.message")}
            onChange={(e) => onChange(e)}
            ref={scrollContainerRef}
          ></textarea>
          <div className={styles.phone}>
            <div className="preview-phone">
              <div className="preview-content">
                <div className="preview-content__wrap">
                  <div className="preview-content__inner">
                    {lang === "en" ? (
                      <span className="preview-content__text">
                        <p>{name}</p>
                        <p>
                          {thankMessage.en.body_text
                            .replace("{{1}}", "")
                            .replace("{{2}}", "")}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: parseHTML(value).innerHTML,
                            }}
                          />
                        </p>
                      </span>
                    ) : (
                      <span className="preview-content__text">
                        <p>{name}</p>
                        <p>
                          {thankMessage.ar.body_text
                            .replace("{{1}}", "")
                            .replace("{{2}}", "")}
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: parseHTML(value).innerHTML,
                          }}
                        />
                      </span>
                    )}
                    <div className="preview-content__bottom">
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <button onClick={onSendThankYouMessage} className={`${styles.btn}`}>
              {t("buttons.sendNow")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewModal
